import React from 'react';

interface Feature {
    title: string;
    description: string;
    icon: string;
}

const features: Feature[] = [
    {
        title: 'Daily Text Prompts',
        description: 'Get inspiring and thought-provoking prompts to guide your journaling journey.',
        icon: '📝',
    },
    {
        title: 'Mood Tracking',
        description: 'Record how you feel each day and observe patterns over time to improve self-awareness.',
        icon: '😊',
    },
    {
        title: 'Personal Insights',
        description: 'Reflect on your entries to uncover trends, improve mental clarity, and set goals.',
        icon: '📊',
    },
    {
        title: 'Guided Growth',
        description: 'Stay consistent with reminders and track streaks to build a mindful habit.',
        icon: '🌱',
    },
    {
        title: 'Secure & Private',
        description: 'Your thoughts are safe with us. Your journal is encrypted and yours alone.',
        icon: '🔒',
    },
];

const Features: React.FC = () => {
    return (
        <section className="features bg-gray-100 py-16 px-6">
            <div className="max-w-5xl mx-auto text-center">
                <h2 className="text-3xl font-bold mb-8">Why You'll Love Our App</h2>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="feature-card bg-white shadow-md p-6 rounded-lg hover:shadow-lg transition duration-300"
                        >
                            <div className="text-4xl mb-4">{feature.icon}</div>
                            <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;
