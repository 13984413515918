import React from 'react';

const TermsOfService: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Terms of Service</h1>

            <p>
                Welcome to <strong>MymoBot</strong>. By using our app, you agree to the following Terms of Service. Please read them carefully. If you do not agree to these terms, you may not use the app.
            </p>

            <h2 style={styles.heading}>1. Acceptance of Terms</h2>
            <p>
                By accessing or using <strong>MymoBot</strong>, you agree to comply with and be bound by these Terms of Service and our Privacy Policy. These terms apply to all users of the app.
            </p>

            <h2 style={styles.heading}>2. Changes to Terms</h2>
            <p>
                We reserve the right to update or modify these Terms of Service at any time. If changes are made, we will notify you by updating the "Effective Date" at the top of this page. Your continued use of the app after changes constitutes your acceptance of the new terms.
            </p>

            <h2 style={styles.heading}>3. User Responsibilities</h2>
            <p>As a user of <strong>MymoBot</strong>, you agree to the following:</p>
            <ul>
                <li>You will not use the app for any unlawful purpose.</li>
                <li>You are responsible for the accuracy of any information or content you provide within the app.</li>
                <li>You will not attempt to interfere with the app’s functionality or security.</li>
            </ul>

            <h2 style={styles.heading}>4. Intellectual Property</h2>
            <p>
                All content, features, and functionality in <strong>MymoBot</strong>, including but not limited to text, graphics, logos, and software, are owned by <strong>[Your Company Name]</strong> and are protected by copyright and intellectual property laws.
            </p>

            <h2 style={styles.heading}>5. Limitation of Liability</h2>
            <p>
                <strong>MymoBot</strong> is provided "as is" without any warranties, express or implied. We do not guarantee that the app will be error-free or available at all times. In no event shall <strong>[Your Company Name]</strong> be liable for any damages arising out of your use or inability to use the app.
            </p>

            <h2 style={styles.heading}>6. Termination</h2>
            <p>
                We reserve the right to suspend or terminate your access to <strong>MymoBot</strong> at our sole discretion, without notice, for conduct that violates these terms or is harmful to other users, us, or third parties.
            </p>

            <h2 style={styles.heading}>7. Governing Law</h2>
            <p>
                These Terms of Service shall be governed by and construed in accordance with the laws of Washington. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts located in Washington.
            </p>

            <footer style={styles.footer}>
                &copy; {currentYear} MymoBot. All rights reserved.
            </footer>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center' as const,
        marginBottom: '20px',
        color: '#222',
    },
    heading: {
        marginTop: '20px',
        marginBottom: '10px',
        color: '#222',
    },
    footer: {
        textAlign: 'center' as const,
        marginTop: '20px',
        fontSize: '0.9em',
        color: '#555',
    },
};

export default TermsOfService;
