import { BrowserRouter, Link, Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import SplashScreen from './components/home';
import PrivacyStatement from './components/privacy';
import TermsOfService from './components/terms-of-service';
import './App.css';
import './index.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <div style={{ display: 'flex' }}>
          <Routes>
            <Route path="/" element={
              <SplashScreen />
            } />
            <Route path="/privacy" element={
              <PrivacyStatement />
            } />
            <Route path="/terms" element={
              <TermsOfService />
            } />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

function Header() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '200px', background: '#F0F0F0', height: '100vh' }}>
      <div style={{ textAlign: 'center', fontSize: 20, marginBottom: 20, marginTop: 20 }}>Mymo Ops!</div>
      <Link to="/" style={{ padding: '10px', textAlign: 'center' }}>Home</Link>
    </div>
  )
}

export default App;
