import React from 'react';
import Hero from './Hero';
import Features from './Features';
import Footer from './Footer';
import './index.css';

const LandingPage: React.FC = () => {
    return (
        <div className="landing-page">
            <Hero />
            <Features />
            <Footer />
        </div>
    );
};

export default LandingPage;
