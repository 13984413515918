import React from 'react';

const Hero: React.FC = () => {
    return (
        <section className="hero bg-gradient-to-r from-blue-400 to-purple-500 text-white py-20 px-6 text-center">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-5xl font-bold mb-6">Mindful Moments, One Prompt at a Time</h1>
                <p className="text-lg mb-8">
                    Discover a better way to reflect, grow, and track your emotions. With guided prompts and
                    mood tracking, building mindfulness has never been easier.
                </p>
                <div>
                    <a
                        href="/download"
                        className="bg-white text-blue-600 px-6 py-3 rounded-full font-semibold shadow-lg hover:bg-gray-100 transition duration-300"
                    >
                        Start Your Journey
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Hero;
