import React from 'react';

const Footer: React.FC = () => {
    return (
        <footer className="footer bg-gray-800 text-white py-6">
            <div className="max-w-4xl mx-auto text-center">
                <p className="mb-4">&copy; {new Date().getFullYear()} Mymobot. All rights reserved.</p>
                <div className="space-x-4">
                    <a href="/privacy" className="hover:text-gray-400">
                        Privacy Policy
                    </a>
                    <a href="/terms" className="hover:text-gray-400">
                        Terms of Service
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
