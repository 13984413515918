import React from 'react';

const PrivacyStatement: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Privacy Statement</h1>

            <p>
                At <strong>MymoBot</strong>, your privacy is our top priority. This privacy statement explains how we collect, use, and protect your information when you use our app.
            </p>

            <h2 style={styles.heading}>Information We Collect</h2>
            <p>
                When you use <strong>MymoBot</strong>, you may provide messages, journal entries, or other text-based input to interact with the app’s features.
            </p>

            <h2 style={styles.heading}>How We Use Your Information</h2>
            <ul>
                <li>
                    <strong>Generating Responses:</strong> We anonymize your messages before sharing them with <strong>OpenAI</strong>. This means that any personally identifiable information is removed to ensure your privacy. OpenAI uses the anonymized data solely to generate meaningful and personalized responses to enhance your journaling and mindfulness experience.
                </li>
                <li>
                    <strong>No Secondary Sharing or Selling:</strong> We will <strong>never</strong> share your messages with any other third party beyond OpenAI, nor will we sell or use your data for advertising or external purposes.
                </li>
            </ul>

            <h2 style={styles.heading}>Data Protection</h2>
            <p>We are committed to safeguarding your information and ensuring your privacy:</p>
            <ul>
                <li>
                    <strong>Anonymization:</strong> All messages are stripped of personally identifiable information before being shared with OpenAI.
                </li>
                <li>
                    <strong>Secure Transmission:</strong> Messages are transmitted securely using encryption.
                </li>
                <li>
                    <strong>Minimal Retention:</strong> We only store your data as necessary for app functionality and never beyond the required time.
                </li>
            </ul>

            <h2 style={styles.heading}>Your Privacy Choices</h2>
            <ul>
                <li>
                    <strong>Opt-Out:</strong> If you choose not to share messages for generating responses, certain app features may be limited.
                </li>
            </ul>

            <h2 style={styles.heading}>Changes to This Privacy Statement</h2>
            <p>
                We may revise this Privacy Statement as necessary. Updates will be reflected in the{' '}
                <strong>Effective Date</strong> at the top of this page. Please review this statement regularly to stay informed of any changes.
            </p>

            <p>
                By using <strong>MymoBot</strong>, you acknowledge that you have read and understood this Privacy Statement and consent to the practices described.
            </p>

            <footer style={styles.footer}>
                &copy; {currentYear} MymoBot. All rights reserved.
            </footer>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
        backgroundColor: '#fff',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    title: {
        textAlign: 'center' as const,
        marginBottom: '20px',
        color: '#222',
    },
    heading: {
        marginTop: '20px',
        marginBottom: '10px',
        color: '#222',
    },
    footer: {
        textAlign: 'center' as const,
        marginTop: '20px',
        fontSize: '0.9em',
        color: '#555',
    },
};

export default PrivacyStatement;
